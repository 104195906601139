import React from 'react'
import './message.css'

const Message = () => {
  return (
    <React.Fragment>
      <div className='message__container'>
        <div className='message__container--text'>
          <h1>You yourself must be the change you want to see in the world</h1>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Message
